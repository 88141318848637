<template>
    <div class="container">
        <div style="text-align:center">
            <h5>Vacantes Totales </h5>
        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
            <apexchart width="700" type="bar" :options="options" :series="series">
            </apexchart>
        </div>
    </div>
</template>

<script>
import { CompanyService } from "@/services";
import VueApexCharts from 'vue3-apexcharts';
import { ref } from "vue";


export default {
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            options: ref({
                chart: {
                    id: 'vuechart-example',
                    type: 'bar'
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    title: {
                        text: 'Vacantes'
                    }
                },
                fill: {
                    opacity: 1,
                    colors: ['#FF7F50', '#A9A9A9']
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '15%',
                        distributed: false,
                        colors: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                colors: []
                            }],
                            backgroundBarColors: [],
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 0,
                        },
                        dataLabels: {
                            position: 'top',
                            maxItems: 10,
                            total: {
                                enabled: true,
                                formatter: undefined,
                                offsetX: 0,
                                offsetY: 0,
                                style: {
                                    color: '#373d3f',
                                    fontSize: '12px',
                                    fontFamily: undefined,
                                    fontWeight: 600
                                }
                            }
                        }
                    }
                }
            }),
            series: [{
                name: 'vacante',
                data: []
            }],

            user: JSON.parse(localStorage.getItem("user"))
        }
    },
    mounted() {
        this.getVacantesTotalesByUserId(this.user._id);
    },
    methods: {
        async getVacantesTotalesByUserId(userId) {

            try {

                const response = await CompanyService.getVacantesTotalesByUserId(userId);
                let { data } = response.data;

                data.map((item) => {
                    this.options.xaxis.categories.push(item.mes)
                });

                data.map((item) => {
                    this.series[0].data.push(item.vacantes)
                });


                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));


                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };


            } catch (e) {
                console.log(e);
            }
        }
    }
}
</script>


<style></style>